















































































































import Vue from "vue";

import { post, t } from "#root/lib/utils";
import WorldItem from "#root/components/menu/WorldItem.vue";
import nodegzip from "node-gzip";
const WorldCreation = () => import("#root/components/menu/WorldCreation.vue");

import { WorldInfo, WorldDeletion } from "#mws/interfaces";
import { Rules, Endpoints, LocaleKeys } from "#mws/data";
import SessionModule from "#root/store/session";
import PersistentModule from "#root/store/persistent";
import AppModule from "#root/store/app";
import Store from "#root/store/store";

export default Vue.extend({
  name: "WorldsPanel",
  components: { WorldItem, WorldCreation },
  data: () => ({
    tab: "mine",
    worldRefreshTimer: null as number | null,
    creatingWorld: false,
    worldLimit: Rules.WORLD.MAX_WORLDS,
    lk: LocaleKeys,
    onlineWorlds: [] as WorldInfo[],
    newWorlds: [] as WorldInfo[],
  }),
  computed: {
    myWorlds(): WorldInfo[] {
      const profile = SessionModule.profile;
      if (profile === undefined) return [];
      return profile.worlds;
    },
  },
  methods: {
    t: t,
    refreshWorlds(background = true): void {
      if (PersistentModule.session === undefined) return;

      post<null, string>({
        endpoint: Endpoints.HTTP.worlds.get,
        auth: true,
        background,
        manualCatch: true,
      })
        .then((result) => {
          return nodegzip.ungzip(Buffer.from(result, "base64"));
        })
        .then((data) => {
          const objectData = JSON.parse(data.toString());
          this.onlineWorlds = objectData.online;
          this.newWorlds = objectData.new;
          // wow
          const myWorldIds = new Set<number>();
          for (const myWorld of this.myWorlds) {
            myWorldIds.add(myWorld.id);
            this.$set(myWorld, "players", 0);
          }
          for (const world of this.onlineWorlds) {
            if (myWorldIds.has(world.id)) {
              for (const myWorld of this.myWorlds) {
                if (myWorld.id === world.id) {
                  this.$set(myWorld, "players", world.players);
                  continue;
                }
              }
            }
          }
        })
        .catch((error) => {
          if (error === LocaleKeys.server.auth.error.invalidToken) return;

          AppModule.showAlert({
            title: { key: LocaleKeys.alert.error },
            message: { key: error },
            buttons: [
              {
                text: { key: LocaleKeys.alert.retry },
                color: "primary",
                action: () => {
                  this.refreshWorlds(false);
                },
              },
              {
                text: { key: LocaleKeys.alert.logout },
                color: "error",
                action: () => {
                  Store.logout();
                  AppModule.loadingAlert();
                  this.$router.push("/login");
                },
              },
            ],
          });
        });
    },
    deletePrompt(index: number): void {
      const id = this.myWorlds[index].id;
      AppModule.showAlert({
        title: { key: LocaleKeys.worldDeleteAlert.title },
        message: {
          key: LocaleKeys.worldDeleteAlert.message,
          values: [this.myWorlds[index].name],
        },
        buttons: [
          {
            text: { key: LocaleKeys.alert.cancel },
            color: "primary",
            action: AppModule.hideAlert,
          },
          {
            color: "error",
            text: { key: LocaleKeys.worldDeleteAlert.delete },
            action: () => {
              this.deleteWorld(id, index);
            },
          },
        ],
        persistent: false,
      });
    },
    deleteWorld(id: number, index: number): void {
      post<WorldDeletion, any>({
        endpoint: Endpoints.HTTP.worlds.delete,
        auth: true,
        data: { id },
      }).then(() => {
        this.myWorlds.splice(index, 1);
      });
    },
  },
  mounted() {
    this.worldRefreshTimer = setInterval(this.refreshWorlds, 5 * 1000);
    this.refreshWorlds(false);
  },
  beforeDestroy() {
    if (this.worldRefreshTimer !== null) {
      clearInterval(this.worldRefreshTimer);
    }
  },
});
