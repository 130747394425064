



















































import Vue from "vue";
import assert from "assert";

import { t } from "#root/lib/utils";
import WebSocketManager from "#root/lib/webSocketManager";
import { EnvironmentVariables } from "#root/lib/environmentVariables";

import { WorldInfo } from "#mws/interfaces";
import { LocaleKeys } from "#mws/data";
import AppModule from "#root/store/app";
import PersistentModule from "#root/store/persistent";
import SessionModule from "#root/store/session";

export default Vue.extend({
  name: "WorldItem",
  data: function () {
    return {
      lk: LocaleKeys,
    };
  },
  methods: {
    t: t,
    join() {
      AppModule.loadingAlert();
      WebSocketManager.connect(`${EnvironmentVariables.WEBSOCKET_URL}`)
        .then(() => {
          const session = PersistentModule.session;
          assert(session !== undefined);
          return WebSocketManager.sendRequest(["join"], {
            auth: session,
            id: this.world.id,
          });
        })
        .then((data) => {
          SessionModule.joinedWorld = data;
          console.log("Joined world:", data);
          this.$router.push("/game");
        })
        .catch((error) => {
          AppModule.showAlert({
            title: { key: LocaleKeys.alert.error },
            message: { key: error },
            buttons: [
              {
                text: { key: LocaleKeys.alert.ok },
                color: "primary",
                action: AppModule.hideAlert,
              },
            ],
          });
        });
    },
  },
  props: {
    world: {
      type: Object as () => WorldInfo,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
});
